import React from 'react';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';
import CtaLink from '@/components/common/cta-link';
import SEO from '@/components/seo';

const simplePortableText = {
  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel} className="text-purply-blue">
          {children}
        </a>
      );
    },
  },
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
};

const Success = () => {
  const query = useStaticQuery(graphql`
    {
      sanityGetStarted {
        title
        subtitle
        image {
          asset {
            gatsbyImageData
          }
        }
        formSuccessMessageTitle
        _rawFormSuccessMessageSubtitle(resolveReferences: { maxDepth: 10 })
        formSuccessMessageCta {
          url
          label
        }
      }
    }
  `);

  const data = query.sanityGetStarted;

  return (
    <Layout>
      <Container className="pb-44 pt-6 md:pb-52 md:pt-20">
        <FormSuccess
          image={data.image}
          cta={data.formSuccessMessageCta}
          title={data.formSuccessMessageTitle}
          subtitle={data._rawFormSuccessMessageSubtitle}
        />
      </Container>
    </Layout>
  );
};

export default Success;

const FormSuccess = ({ cta, title, subtitle, image }) => {
  return (
    <div className="flex flex-col items-center gap-16 md:flex-row md:justify-between">
      <div>
        <GatsbyImage
          alt=""
          image={image.asset.gatsbyImageData}
          className="w-full md:max-w-lg"
        />
      </div>

      <div className="flex flex-col items-center text-center md:items-start md:text-left">
        <h1 className="text-[30px] md:max-w-lg md:text-[40px] md:leading-tight lg:text-[56px]">
          {title}
        </h1>
        <p className="font-outfit mt-4 text-lg font-light md:max-w-lg">
          <PortableText
            value={subtitle}
            components={simplePortableText as any}
          />
        </p>
        <CtaLink
          isExternal
          url={cta.url}
          label={cta.label}
          className="mt-6 max-w-[200px]"
        />
      </div>
    </div>
  );
};

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
    <SEO title={`Thank You!`} />
  </>
);
